import React from 'react'
import { Link } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import Navbar from './Navbar'
import { StaticQuery, graphql } from 'gatsby'
import layoutStyles from "./Layout.module.css"

// For prism.js line numbering
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
// NOTE: GATSBY CSS add-ons for line number fixes added to that CSS file!
// SEE https://www.gatsbyjs.org/packages/gatsby-remark-prismjs/?=prismjs

class Layout extends React.Component {

  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      // This is the top level header for the site
      header = (
          <h1
            style={{
              ...scale(1.5),
              marginBottom: rhythm(1.5),
              marginTop: 0,
              color: `#81469f`,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
      )
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
            fontSize: `40px`,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `hotpink`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }

    return (
      <div>

        {/*
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <Navbar siteTitle={data.site.siteMetadata.title} />
          )}
        />
        */}

        <Navbar />

        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(32),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            color: `#e0e0e0`,
          }}
        >
          {header}
          {children}
          <footer>

          <StaticQuery
            query={graphql`
              query SiteTitleQuery {
                site {
                  siteMetadata {
                    author
                  }
                }
              }
            `}
            render={data => (
              <div style={{ paddingTop: 15 }}>
                © {data.site.siteMetadata.author} - {new Date().getFullYear()} - Built with {` `} <a href="https://www.gatsbyjs.org">Gatsby</a>
              </div>
            )}
          />

          </footer>
        </div>
      </div>
    )
  }
}

export default Layout